@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Ubuntu', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #6771fa 1.84%, #00ffb3 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #6771fa -13.86%, #a89f4d 120%);
  
  --color-bg: #050b14;
  --color-footer : #091f36;
  --color-blog: #045404;
  --color-text: #66a3a5;
  --color-subtext: #a8aa87;
}