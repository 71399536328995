@keyframes textShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#logoPrimaryText {
  transform-origin: center;
  animation: 3s ease-in-out forwards textShow;
}

@keyframes lineShow {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

#logoUnderline {
  transform-origin: top left;
  animation: 1s ease-in-out forwards lineShow;
}