ul {
  color: white;
  list-style: none;
}

.listIcon {
  display: flex;
  align-items: center;
  margin-top: 0.2em;
}

.listIcon .dropIcon {
  width: 30px;
}

.listIcon p {
  font-size: 20px;
}