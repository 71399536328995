.swmmReact__possibility {
  display: flex;
  flex-direction: row;
}

.swmmReact__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.swmmReact__possibility-image img {
  width: 100%;
  height: 100%;
}

.swmmReact__possibility-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.swmmReact__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #323;
}

.swmmReact__possibility-content h4:last-child {
  color: #ff8A71;
}

.swmmReact__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.swmmReact__possibility-content p {
  color: #248;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .swmmReact__possibility {
      flex-direction: column;
  }

  .swmmReact__possibility-image {
      margin: 1rem 0;
  }

  .swmmReact__possibility-image img {
      width: unset;
      height: unset;
  }

  .swmmReact__possibility-content {
      margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .swmmReact__possibility-image img {
      width: 100%;
      height: 100%;
  }
}
