/*.listbox {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-footer);
  padding: 1em;
  margin: 2em;
}

.listbox > * {
  flex: 1 1 300px;
}*/

.listbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  background-color: var(--color-footer);
  padding: 1em;
  margin: 2em;
  border-radius: 10px;
}

ul {
  list-style-type: none;
}

li:hover {
  color: steelblue;
}