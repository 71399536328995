.swmmReact__features {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
  padding: 2rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.swmmReact__features-heading {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  text-align: left;
  margin-right: 5rem;
}

.swmmReact__features-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
}

.swmmReact__features-heading p {
  color: #eee;
  font-size: 16px;
  line-height: 30px;
  font-weight: 800;
  font-family: var(--font-family);

  margin-top: 2rem;
}

.swmmReact__features-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

@media screen and (max-width: 990px) {
  .swmmReact__features {
      flex-direction: column;
  }

  .swmmReact__features-heading {
      margin: 0 0 2rem 0;
  }
}


@media screen and (max-width: 550px) {
  .swmmReact__features-heading h1 {
      font-size: 28px;
      line-height: 38px;
  }
}