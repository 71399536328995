.swmmReact__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 6rem;
}

.swmmReact__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.swmmReact__navbar-links_logo {
  margin-right: 2rem;
}

.swmmReact__navbar-links_logo svg {
  width: 200px;
  height: 30px;
}

.swmmReact__navbar-links_container {
  display: flex;
  right: 0;
  flex-direction: row-reverse;
}

.swmmReact__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.swmmReact__navbar-links_container > p,
.swmmReact__navbar-sign p,
.swmmReact__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  margin: 0 2rem;
  cursor: pointer;
}

.swmmReact__navbar-sign button,
.swmmReact__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #049c1e;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.swmmReact__navbar-menu {
  margin-left: 1rem;

  position: relative;
}

.swmmReact__navbar-menu svg{
  cursor: pointer;
}

.swmmReact__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;

  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);

  z-index: 1;
}

.swmmReact__navbar-menu_container p {
  margin: 1rem 0;
}

.swmmReact__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1444px) {
  .swmmReact__navbar-links_container {
  }

  .swmmReact__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .swmmReact__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .swmmReact__navbar {
    padding: 2rem;
  }

  .swmmReact__navbar-sign {
    display: none;
  }

  .swmmReact__navbar-menu_container {
    top: 20px;
  }

  .swmmReact__navbar-menu_container-links-sign {
    display: block;
  }
}