@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

body {
  max-width: 1800px;
  background: var(--color-bg);
  min-height: 100vh;
  overflow-x: hidden;
  margin: auto;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 30px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  background: white;
}

.logo {
  padding-top: 12px;
  color: #359381;
  pointer-events: none;
  margin-right: auto;
}

.logo img {
  height: 1.3em;
  border: 3px solid #2482cd;
  border-radius: 50%;
}

.navigation a {
  text-decoration: none;
  color: #3593cd;
  padding: 6px;
  border-radius: 20px;
  margin: 0 10px;
  font-weight: 600;
}

.navigation a:hover, .navigation a.active {
  background: #3593cd;
  color: #fff;
}

.parallax {
  max-width: 1800px;
  margin: auto;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

#text {
  position: absolute;
  font-size: 3em;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.parallax img {
  position: absolute;
  margin: auto;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}

.parallax {
  height: 1250px;
}

.parallax div {
}

.modiv {
  position: absolute;
  top: 0;
  left: 0;
}

.modiv div {
  margin: auto;
  pointer-events: none;
}

.sec {
  color: #222;
  position: relative;
  background-color: #e0e5e0;
  padding: 20px;
}

.sec h2 {
  font-size: 3em;
  margin-bottom: 10px;
}

.sec p {
  font-size: 1em;
  font-weight: 500;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	/*-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;*/
  -webkit-animation: scale-up-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes scale-up-top {
  0% {
    transform-origin: top right;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    transform-origin: top right;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-top {
  0% {
    transform-origin: top right;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    transform-origin: top right;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}